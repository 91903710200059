import request from '@/plugins/axios'


// 搜索记录列表
export const apiCommunitySearchRecordLists = (params: any) => request.get('/community.community_search_record/lists', { params })

// 添加搜索记录
export const apiCommunitySearchRecordAdd = (params: any) => request.post('/community.community_search_record/add', params)

// 编辑搜索记录
export const apiCommunitySearchRecordEdit = (params: any) => request.post('/community.community_search_record/edit', params)

// 删除搜索记录
export const apiCommunitySearchRecordDelete = (params: any) => request.post('/community.community_search_record/delete', params)

// 搜索记录详情
export const apiCommunitySearchRecordDetail = (params: any) => request.get('/community.community_search_record/detail', { params })

export const apiCommunitySearchRecordSwitchStatus = (params: any) => request.post('/community.community_search_record/status', params)